import React, {useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { localizeText } from '../../utils/locale';
import ImageModal from './ImageModal';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);



function SwipeableTextMobileStepper({selectedLanguage, product}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [openImageModal, setOpenImageModal] = React.useState(0);
  const [openImageUrl, setOpenImageUrl] = React.useState(null);

  useEffect(() => {
    !product && console.log('ürün yok')

  },[product]);

  const maxSteps = product?.images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const onClickImage = (imageUrl) => {
    setOpenImageModal(openImageModal+1)
    setOpenImageUrl(imageUrl)
  };

  return (
    product &&  <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
      <Typography alt={product.detail[selectedLanguage].alt} variant="h6" component="h2" style={{fontWeight: 700}}>{product.detail[selectedLanguage].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
      allowFullScreen={true}
        autoplay={false}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {product?.images.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
              onClick={()=>{onClickImage(step.imgPath)}}

                component="img"
                sx={{
                  height: 500,
                  display: 'block',
                  maxWidth: 600,
                  overflow: 'hidden',
                  width: '100%',
                  cursor: 'pointer'
                
                }}
                src={step.imgPath}
                alt={product.detail[selectedLanguage].alt}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}

        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {localizeText('next', selectedLanguage)}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {localizeText('back', selectedLanguage)}
          </Button>
        }
      />
{     openImageModal>0 && <ImageModal isOpen={openImageModal} imageUrl={openImageUrl}/>}    
</Box>
  );
}

export default SwipeableTextMobileStepper;
