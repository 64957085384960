import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import productCurvyLines from "../../images/productCurvyLines.png";
import rating from "../../images/icons/rating.png";
import experience from "../../images/icons/experience.png";
import wallet from "../../images/icons/wallet.png";

import { localizeText } from "../../utils/locale";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    display: "flex",
    position: "relative",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 64,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  },
});

function ProductValues(props) {
  const { classes, selectedLanguage } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src={productCurvyLines}
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={rating} alt="rating" />
              <Typography variant="h6" className={classes.title}>
                {localizeText("title", selectedLanguage)}
              </Typography>
              <Typography variant="h5">
                {localizeText("titleDescription", selectedLanguage)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={experience}
                alt="experience"
              />
              <Typography variant="h6" className={classes.title}>
                {localizeText("title2", selectedLanguage)}
              </Typography>
              <Typography variant="h5">
                {localizeText("titleDescription2", selectedLanguage)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={wallet} alt="wallet" />
              <Typography variant="h6" className={classes.title}>
                {localizeText("title3", selectedLanguage)}
              </Typography>
              <Typography variant="h5">
                {localizeText("titleDescription3", selectedLanguage)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
