import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import AppBar from "../components/AppBar";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import mimozaLogo from "../../images/mimoza-logo.svg";
import { localizeText } from "../../utils/locale";

const styles = (theme) => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: "space-between",
  },
  left: {
    maxWidth: 164,
    overflow: "hidden",
    marginLeft: "5%",
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rightLink: {
    fontSize: "0.85rem",
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: "#B3002F",
  },
  logo: {
    marginTop: 16,
    // height: 'auto',
    // minWidth: 64,
    // maxWidth: 164,
    width: "100%",
    objectFit: "contain",
    // maxWidth: 164,
    // // width:'auto'
  },
});

const NavbarView = ({ classes, selectedLanguage }) => {
  return (
    <div>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "#fff", borderBottom: "solid 1px #ebebeb" }}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <Link
              variant="h6"
              underline="none"
              color="inherit"
              className={classes.title}
              href="/"
            >
              <img
                className={classes.logo}
                src={mimozaLogo}
                alt={"Mimoza Store"}
              ></img>
            </Link>
          </div>

          <div className={classes.right}>
            <Link
              variant="h6"
              underline="none"
              className={clsx(classes.rightLink, classes.linkSecondary)}
              href="/all-products"
            >
              {localizeText("allProducts", selectedLanguage)}
            </Link>
            <Link
              variant="h6"
              underline="none"
              className={clsx(classes.rightLink, classes.linkSecondary)}
              href="/contact"
            >
              {localizeText("contactUs", selectedLanguage)}
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
};

NavbarView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavbarView);
