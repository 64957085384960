import React, { Fragment } from "react";
import withRoot from "./modules/withRoot";

// components
import NavbarView from "./modules/views/NavbarView";
import ProductDetail from "./modules/views/ProductDetail";
import AppFooter from "./modules/views/AppFooter";
import { useParams } from "react-router-dom";
import { productList } from "./utils/productList";

const Product = (props) => {
  const { selectedLanguage } = props;
  const { id, productColor, handleColor } = useParams();

  const getProduct = (productColor, handleColor) => {
    return handleColor
      ? productList[id][productColor][handleColor]
      : productList[id][productColor];
  };

  return (
    <Fragment>
      <NavbarView selectedLanguage={selectedLanguage} />
      <ProductDetail
        selectedLanguage={selectedLanguage}
        product={getProduct(productColor, handleColor)}
        productId={id}
        productColor={productColor}
        handleColor={handleColor}
      />

      <AppFooter selectedLanguage={selectedLanguage} withoutMainPage={false} />
    </Fragment>
  );
};

export default withRoot(Product);
