import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import productCTAImageDots from "../../images/productCTAImageDots.png";
import whatsappLogo from "../../images/icons/whatsapp-logo.ico";
import { localizeText } from "../../utils/locale";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: 0,
    display: "flex",
    flexDirection: "column",
    top: 0,
    bottom: 0,
  },
  body: {
    flex: 4,
  },
  cardWrapper: {
    zIndex: 1,
    maxHeight: 600,
  },
  card: {
    display: "flex",
    justifyContent: "center",
    //padding: theme.spacing(8, 3)
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
  },
  imagesWrapper: {
    position: "relative",
    zIndex: 1,
    maxHeight: 500,
  },
  imageDots: {
    position: "absolute",
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: "100%",
    background: `url(${productCTAImageDots})`,
    maxHeight: 400,
  },
  image: {
    position: "absolute",
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: "80%",
    //maxWidth: 500
  },
});

const ContactUs = ({ classes, selectedLanguage }) => {

  
  return (
    <Container className={classes.root} component="section">
      <Grid container style={{ maxHeight: 400 }}>
        <Grid item xs={12} md={7} lg={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form className={classes.cardContent}>
              <Typography variant="h6" component="h2" gutterBottom>
                {localizeText("contactUs", selectedLanguage)}
              </Typography>
              <Typography variant="subtitle1">
                {localizeText("address", selectedLanguage)}
              </Typography>
              <Typography variant="subtitle1">
                {localizeText("email", selectedLanguage)}
              </Typography>
              <Typography variant="subtitle1">
                {localizeText("phone", selectedLanguage)}
                <br />
                <a
                  href="https://api.whatsapp.com/send?phone=905319670789"
                  target={"_blank"}
                >
                  <img src={whatsappLogo} alt="whatsapp" />
                </a>
              </Typography>
            </form>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item xs={6} md={5} lg={6} className={classes.imagesWrapper}>
            <div
              className={classes.imageDots}
              background={productCTAImageDots}
            />
            {/* <img
              src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750&q=80"
              alt="call to action"
              className={classes.image}
            /> */}
          </Grid>
        </Hidden>
      </Grid>
      {/* <Snackbar
        open={open}
        onClose={setOpen}
        message="We will send you our best offers, once a week."
      /> */}
    </Container>
  );
};

ContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactUs);
