const translations = {
    'en': {
      'header': 'Welcome',
      'paragraph': 'Our designs are will be taken attention and important parts in your kitchen, our special design products are will add a very different atmosphere.',
      'more': 'Discover the experience',
      'title': 'The best luxury furniture for your kitchen',
      'titleDescription': 'Beautify your kitchen with our new trend kitchen furnitures and special design.',
      'title2': 'New experiences',
      'titleDescription2': 'When you cooking everything is will look better in your eyes with our new generation designs. Your passing minutes will not be alike in your kitchen.',
      'title3': 'Exclusive rates',
      'titleDescription3': 'You will access modern furniture with specially rates that you will not find anywhere else.',
      'productHeader': 'Featured Products',
      'productName1': 'Bread Box',
      'productName2': 'Mail Box',
      'productName3': 'Napkin Holder For Restaurant',
      'productName4': 'Napkin Holder For Home',
      'productName5': 'News Box',
      'askQuestionHeader': 'Got any questions? Need help?',
      'askQuestionHeader2': 'We are here to help. Get in touch!',
      'language': 'Language',
      'lang-choice': 'Choose your language:',
      'contactUs': 'Contact Us',
      'allProducts': 'All Products',
      'forAllProducts': 'For All Products, Please Click!',
      'address': 'Adress: Fevzi Çakmak Mah. Yıldırım Beyazıt Cad. Bahçelievler / İstanbul / Türkiye',
      'email': 'e-mail: okay.tonka@gmail.com',
      'phone': 'Phone: +9 0(531) 967-0789',
      'forStorePageHeader': 'Market Place Store Pages',
      'specialOfferText': 'Please contact us with multiple purchase for special prices.',
      'remit': 'Remit:',
      'remitDescription': 'If you want create an order with remit, you can contact us on Whatsapp:',
      'toMarketPlaces':'Or if you want to create an order directly, you can click our marketplace links:',
      'next': 'Next',
      'back': 'Back',
      'and': 'And',
      'holder': 'Holder',
      'with-legs': 'With Metal Legs',
      'without-legs': 'Without Legs',
      'color': 'Color',
      'colors': 'Colors:',
      'white': 'White',
      'black': 'Black',
      'gold': 'Gold',
      'chromium': 'Chromium',
      'walnut': 'Walnut',
      'african-walnut': 'African Walnut',
      'wenge': 'Wenge',
      'walnut': 'Walnut',
      'pieces' : 'Pieces'

    },
    'tr': {
        'header': 'HOŞ GELDİNİZ',
        'paragraph': 'Modern tasarımlarımız mutfağınızın en dikkat çeken ve önemli parçaları olacak, özel tasarım ürünlerimiz mutfağınıza çok ayrı bir hava katacak.',
        'more': 'Deneyimi keşfedin',
        'title': 'MUTFAĞINIZ İÇİN EN LÜKS MOBİLYALAR',
        'titleDescription': 'Yeni trend mutfak eşyaları ve özel tasarımlarımız ile mutfağınızı güzelleştirin.',
        'title2': 'YENİ DENEYİMLER',
        'titleDescription2': 'Yeni nesil tasarımlarımız ile mutfağınızda çalışırken her şey gözünüze daha güzel gelecek. Mutfağınızda geçen dakikalarınız aynı olmayacak.',  
        'title3': 'ÖZEL FİYATLAR',
        'titleDescription3': 'Modern mobilyalara başka hiçbir yerde bulamayacağınız özel fiyatlar ile ulaşacaksınız.',
        'productHeader': 'ÖNE ÇIKAN ÜRÜNLER',
        'productName1': 'Ekmek Kutusu',
        'productName2': 'Posta Kutusu',
        'productName3': 'Restaurant Tipi Peçetelik',
        'productName4': 'Ev Tipi Peçetelik',
        'productName5': 'Gazetelik',
        'askQuestionHeader': 'BİR sorunuz veya yardıma mı İHTİYACINIZ var?',
        'askQuestionHeader2': 'Yardım etmek için buradayız. Butona tıklayın!',
        'language': 'DİL',
        'lang-choice': 'Choose your language:',
        'contactUs': 'İLETİŞİM',
        'allProducts': 'Tüm Ürünler',
        'forAllProducts': 'Tüm Ürünler İÇİN, Lütfen Tıklayın!',
        'address': 'Adres: Fevzi Çakmak Mah. Yıldırım Beyazıt Cad. Bahçelievler / İstanbul / Türkiye',
        'email': 'e-mail: okay.tonka@gmail.com',
        'phone': 'Telefon: +9 0(531) 967-0789',
        'forStorePageHeader': 'PAZARYERİ MAĞAZA SAYFALARI',
        'specialOfferText': 'Çoklu alımlarınızda özel fiyatlar için lütfen bizimle iletişime geçin.',
        'remit': 'Havale:',
        'remitDescription':'Havale ile sipariş oluşturmak için Whatsapp ile bizimle iletişime geçebilirsiniz:',
        'toMarketPlaces':'Veya doğrudan sipariş oluşturmak için ürünümüzün pazaryeri linklerimize gidebilirsiniz:',
        'next': 'Sonraki',
        'back': 'Önceki',
        'and': 'Ve',
        'holder': 'Kulp',
        'with-legs': 'Metal Ayaklı',
        'without-legs': 'Ayaksız',
        'color': 'Renk',
        'colors': 'Renkler:',
        'white': 'Beyaz',
        'black': 'Siyah',
        'gold': 'Altın',
        'chromium': 'Krom',
        'walnut': 'Ceviz',
        'african-walnut': 'Afrika Ceviz',
        'wenge': 'Venge',
        'walnut': 'Ceviz',
        'pieces' : 'Adet'
      },
    // 'deu': {
    //   'header': 'Frühling',
    //   'paragraf': 'Hallo',
    //   'lang-choice': 'Wählen Sie Ihre Sprache:'
    // },
    // 'rus': {
    //   'header': 'Весна',
    //   'paragraf': 'Привет',
    //   'lang-choice': 'Выберите ваш язык:'
    // }
  }
  export const localizeText = (text, selectedLanguage) => {
    return translations[selectedLanguage][text]
  }