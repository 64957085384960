import React, { Fragment } from "react";
import withRoot from "./modules/withRoot";

// components
import NavbarView from "./modules/views/NavbarView";
import ContactUs from "./modules/views/ContactUs";
import AppFooter from "./modules/views/AppFooter";

const Contact = (props) => {
  const { selectedLanguage } = props;

  return (
    <Fragment>
      <NavbarView selectedLanguage={selectedLanguage} />
      <ContactUs selectedLanguage={selectedLanguage} />
      <AppFooter selectedLanguage={selectedLanguage} withoutMainPage={true} />
    </Fragment>
  );
};

export default withRoot(Contact);
