export const productList = {
  "bread-box": {
    white: {
      black: {
        images: [
          {
            imgPath:
              "https://cdn.dsmcdn.com/ty548/product/media/images/20221002/20/183917038/228985831/1/1_org_zoom.jpg",
          },
          {
            imgPath:
              "https://cdn.dsmcdn.com/ty546/product/media/images/20221002/20/183917038/228985831/2/2_org_zoom.jpg",
          },
          {
            imgPath:
              "https://cdn.dsmcdn.com/ty547/product/media/images/20221002/20/183917038/228985831/3/3_org_zoom.jpg",
          },
          {
            imgPath:
              "https://cdn.dsmcdn.com/ty546/product/media/images/20221002/20/183917038/228985831/4/4_org_zoom.jpg",
          },
          {
            imgPath:
              "https://cdn.dsmcdn.com/ty546/product/media/images/20221002/20/183917038/228985831/5/5_org_zoom.jpg",
          },
        ],
        detail: {
          en: {
            label: "MİMOZA DIGNITY BREAD BOX",
            alt: "Mimoza Dignity Wooden Bread Box White Black Handle and Chromium Legs",
            description:
              "Mimoza Dignity Bread Box with large sizes, lid is fully openable to back, Our designs are will be taken attention and important parts in your kitchen, our special design products are will add a very different atmosphere.\nWe will send your Mimoza Dignity Bread Box product with invoice of your name",
            size: `Material:Wood MDF\nColor: White\nWeight: 2800 gr.\nWidth: 27 cm.\nLength: 39 cm.\nHeight: 21 cm.`,
          },
          tr: {
            label: "MİMOZA DIGNITY EKMEK KUTUSU",
            alt: "Mimoza Dignity Ahşap Ekmek Kutusu Beyaz Siyah Kulplu ve Krom Ayaklı",
            description:
              "Mimoza Dignity Ekmek Kutusu, geniş boyutları, geriye tamamen açılabilir kapağı, modern beyaz tasarımıyla mutfağınızın en dikkat çeken ve önemli üyelerinden biri olacak. Ayarlanabilir metal ayaklar mutfağınıza çok ayrı bir hava katacak. \nMimoza Dignity Ekmek Kutusu adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 2800 gr.\nEn: 27 cm.\nBoy: 39 cm.\nYükseklik: 21 cm.`,
          },
          isShowMainPage: true,
          price: 700,
          mainPrice : 1000,
          trendyolUrl:
            "https://www.trendyol.com/mimoza/dignity-ahsap-ekmek-kutusu-beyaz-siyah-kulplu-ve-metal-ayakli-p-133287231?boutiqueId=614956&merchantId=227159",
          hepsiburadaUrl:
            "https://www.hepsiburada.com/mimoza-dignity-ahsap-ekmek-kutusu-beyaz-metal-kulp-ve-yukseklik-ayarlanabilir-ayak-p-HBCV00000I4D44",
        },
      },
      chromium: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179687/548812855/1/1_org_zoom.jpg"
          },
          {
            imgPath:"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179687/548812855/2/2_org_zoom.jpg"
          },
          {
            imgPath:"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179687/548812855/3/3_org_zoom.jpg"
          },
          {
            imgPath:"https://cdn.dsmcdn.com/ty549/product/media/images/20221003/20/185179687/548812855/4/4_org_zoom.jpg"
          },
          {
            imgPath:"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179687/548812855/5/5_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA DIGNITY BREAD BOX",
            alt: "Mimoza Dignity Wooden Bread Box White Chromium Handle and Chromium Legs",
            description:
              "Mimoza Dignity Bread Box with large sizes, lid is fully openable to back, Our designs are will be taken attention and important parts in your kitchen, our special design products are will add a very different atmosphere.\nWe will send your Mimoza Dignity Bread Box product with invoice of your name",
            size: `Material:Wood MDF\nColor: White\nWeight: 2800 gr.\nWidth: 27 cm.\nLength: 39 cm.\nHeight: 21 cm.`,
          },
          tr: {
            label: "MİMOZA DIGNITY EKMEK KUTUSU",
            alt: "Mimoza Dignity Ahşap Ekmek Kutusu Beyaz Krom Kulplu ve Krom Ayaklı",
            description:
              "Mimoza Dignity Ekmek Kutusu, geniş boyutları, geriye tamamen açılabilir kapağı, modern beyaz tasarımıyla mutfağınızın en dikkat çeken ve önemli üyelerinden biri olacak. Ayarlanabilir metal ayaklar mutfağınıza çok ayrı bir hava katacak. \nMimoza Dignity Ekmek Kutusu adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 2800 gr.\nEn: 27 cm.\nBoy: 39 cm.\nYükseklik: 21 cm.`,
          },
          isShowMainPage: true,
          price: 720,
          mainPrice : 1000,
          trendyolUrl:"https://www.trendyol.com/mimoza/dignity-ahsap-ekmek-kutusu-beyaz-krom-kulplu-ve-metal-ayakli-p-343725625",
          hepsiburadaUrl:"https://www.hepsiburada.com/mimoza-dignity-ahsap-ekmek-kutusu-beyaz-krom-kulplu-ve-metal-ayakli-p-HBCV00003151GP?magaza=MimozaStore",
        },
      },
    },
  },
  "bread-box-without-legs": {
    white: {
      black: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179717/551087713/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty551/product/media/images/20221003/20/185179717/551087713/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty549/product/media/images/20221003/20/185179717/551087713/3/3_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179717/551087713/4/4_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179717/551087713/5/5_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA DIGNITY BREAD BOX WITHOUT LEGS",
            alt: "Mimoza Dignity Wooden Bread Box White Black Handle",
            description:
              "Mimoza Dignity Bread Box with large sizes, lid is fully openable to back, Our designs are will be taken attention and important parts in your kitchen, our special design products are will add a very different atmosphere.\nWe will send your Mimoza Dignity Bread Box product with invoice of your name",
            size: `Material:Wood MDF\nColor: White\nWeight: 2800 gr.\nWidth: 27 cm.\nLength: 39 cm.\nHeight: 21 cm.`,
          },
          tr: {
            label: "MİMOZA DIGNITY EKMEK KUTUSU AYAKSIZ",
            alt: "Mimoza Dignity Ahşap Ekmek Kutusu Beyaz Siyah Kulplu",
            description:
              "Mimoza Dignity Ekmek Kutusu, geniş boyutları, geriye tamamen açılabilir kapağı, modern beyaz tasarımıyla mutfağınızın en dikkat çeken ve önemli üyelerinden biri olacak.\nMimoza Dignity Ekmek Kutusu adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 2800 gr.\nEn: 27 cm.\nBoy: 39 cm.\nYükseklik: 21 cm.`,
          },
          isShowMainPage: true,
          price: 800,
          mainPrice: 1100,
          trendyolUrl:
"https://www.trendyol.com/mimoza/dignity-ahsap-ekmek-kutusu-beyaz-siyah-kulplu-p-344785763?boutiqueId=61&merchantId=227159&filterOverPriceListings=false&sav=true",
          hepsiburadaUrl:
"https://www.hepsiburada.com/mimoza-dignity-ahsap-ekmek-kutusu-beyaz-siyah-kulplu-p-HBCV00000QUZFR",
        },
      },
      chromium: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty550/product/media/images/20221003/20/185179606/551084815/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty549/product/media/images/20221003/20/185179606/551084815/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty549/product/media/images/20221003/20/185179606/551084815/3/3_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty551/product/media/images/20221003/20/185179606/551084815/4/4_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty549/product/media/images/20221003/20/185179606/551084815/5/5_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA DIGNITY BREAD BOX WITHOUT LEGS",
            alt: "Mimoza Dignity Wooden Bread Box White Chromium Handle",
            description:
              "Mimoza Dignity Bread Box with large sizes, lid is fully openable to back, Our designs are will be taken attention and important parts in your kitchen, our special design products are will add a very different atmosphere.\nWe will send your Mimoza Dignity Bread Box product with invoice of your name",
            size: `Material:Wood MDF\nColor: White\nWeight: 2800 gr.\nWidth: 27 cm.\nLength: 39 cm.\nHeight: 21 cm.`,
          },
          tr: {
            label: "MİMOZA DIGNITY EKMEK KUTUSU AYAKSIZ",
            alt: "Mimoza Dignity Ahşap Ekmek Kutusu Beyaz Krom Kulplu",
            description:
              "Mimoza Dignity Ekmek Kutusu, geniş boyutları, geriye tamamen açılabilir kapağı, modern beyaz tasarımıyla mutfağınızın en dikkat çeken ve önemli üyelerinden biri olacak.\nMimoza Dignity Ekmek Kutusu adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 2800 gr.\nEn: 27 cm.\nBoy: 39 cm.\nYükseklik: 21 cm.`,
          },
          isShowMainPage: true,
          price: 825,
          mainPrice: 1100,
          trendyolUrl:
"https://www.trendyol.com/mimoza/dignity-ahsap-ekmek-kutusu-beyaz-krom-kulplu-p-344783189",
          hepsiburadaUrl:
"https://www.hepsiburada.com/mimoza-dignity-ahsap-ekmek-kutusu-beyaz-krom-kulplu-p-HBCV0000319KSZ"
        },
      },
    },
  },
  "mail-box": {
    white: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty514/product/media/images/20220823/23/164674128/218575069/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty515/product/media/images/20220823/23/164674128/218575069/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty514/product/media/images/20220823/23/164674128/218575069/3/3_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty513/product/media/images/20220823/23/164674128/218575069/4/4_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA MAIL BOX",
            alt: "Mimoza Wooden Mail Box",
            description:
              "Mimoza Wooden Mail Box A4 size envelopes an brochures etc. are easily fit in. Product can be fixed a wall with two dowel and screw in product box.\nWe will send your Mimoza Mail Box product with invoice of your name",
            size: `Material:Wood MDF\nColor: White\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 21 cm.`,
          },
          tr: {
            label: "MİMOZA POSTA KUTUSU",
            alt: "Mimoza Ahşap Posta Kutusu",
            description:
              "Mimoza Ahşap Posta Kutusu A4 Ebatında broşür, zarf vb. kutu içerisine rahatlıkla sığabilir. Ürün kutu içerisinde bulunan iki adet dübel ve vida ile duvara sabitlenir.\nMimoza Posta Kutusu adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 21 cm.`,
          },
          isShowMainPage: true,
          price: 200,
          mainPrice: 250,
          trendyolUrl:
            "https://www.trendyol.com/mimoza/ahsap-posta-kutusu-p-129355786?merchantId=227159&filterOverPriceListings=false",
          hepsiburadaUrl:
            "https://www.hepsiburada.com/mimoza-ahsap-posta-kutusu-beyaz-p-HBCV00000FLDB8",
        },
    },
    walnut: {
      images: [
        {
          imgPath:
"https://cdn.dsmcdn.com/ty971/product/media/images/20230725/17/396797116/982207037/1/1_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty970/product/media/images/20230725/17/396797116/982207037/2/2_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty970/product/media/images/20230725/17/396797116/982207037/3/3_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty969/product/media/images/20230725/17/396797116/982207037/4/4_org_zoom.jpg"
        },
      ],
      detail: {
        en: {
          label: "MİMOZA MAIL BOX",
          alt: "Mimoza Wooden Mail Box",
          description:
            "Mimoza Wooden Mail Box A4 size envelopes an brochures etc. are easily fit in. Product can be fixed a wall with two dowel and screw in product box.\nWe will send your Mimoza Mail Box product with invoice of your name",
          size: `Material:Wood MDF\nColor: Walnut\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 21 cm.`,
        },
        tr: {
          label: "MİMOZA POSTA KUTUSU",
          alt: "Mimoza Ahşap Posta Kutusu",
          description:
            "Mimoza Ahşap Posta Kutusu A4 Ebatında broşür, zarf vb. kutu içerisine rahatlıkla sığabilir. Ürün kutu içerisinde bulunan iki adet dübel ve vida ile duvara sabitlenir.\nMimoza Posta Kutusu adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
          size: `Malzeme:Ahşap MDF. Lam \nRenk: Ceviz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 21 cm.`,
        },
        isShowMainPage: true,
        price: 200,
        mainPrice: 250,
        trendyolUrl:
"https://www.trendyol.com/mimoza/ahsap-posta-kutusu-p-746170877",
        //hepsiburadaUrl: "https://www.hepsiburada.com/mimoza-ahsap-posta-kutusu-beyaz-p-HBCV00000FLDB8",
      },
  },

  },
  "napkin-holder": {
    white: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty520/product/media/images/20220901/10/167929912/556609262/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty521/product/media/images/20220901/10/167929912/556609262/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty520/product/media/images/20220901/10/167929912/556609262/3/3_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA NAPKIN HOLDER FOR RESTAURANT",
            alt: "Mimoza Wooden Desktop Horizontal Napkin Holder White",
            description:
              "Mimoza Wooden Napkin Holder Desktop napkin dispenser with wooden high quality coil spring system for restaurants, cafes, tea gardens.\nWe will send your Mimoza Napnkin Holder product with invoice of your name",
            size: `Material:Wood MDF\nColor: White\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 7,5 cm.`,
          },
          tr: {
            label: "MİMOZA PEÇETELİK DİSPENSER RESTORAN TİPİ",
            alt: "Mimoza Ahşap Masaüstü Yatay Peçetelik Beyaz Dispenser",
            description:
              "Mimoza Ahşap Peçetelik - Dispenser Restaurant, kafeterya, çay bahçelerine, ahşap kaliteli helezon yay sistemli masaüstü peçete dispenseri.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 7,5 cm.`,
          },
          isShowMainPage: true,
          price: 130,
          mainPrice: 170,
          specialPrice: [{offer: 6, price: 540},
          {offer: 12, price: 1060},
          {offer: 24, price: 2030}],
          trendyolUrl:
"https://www.trendyol.com/mimoza/ahsap-masaustu-yatay-pecetelik-beyaz-p-347605106",
          // hepsiburadaUrl:
          //   "https://www.hepsiburada.com/mimoza-ahsap-posta-kutusu-beyaz-p-HBCV00000FLDB8",
        },
    },
    "african-walnut": {
      images: [
        {
          imgPath:
"https://cdn.dsmcdn.com/ty615/product/media/images/20221130/23/225075558/627965371/1/1_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty616/product/media/images/20221130/23/225074401/627963747/2/2_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty615/product/media/images/20221130/23/225074401/627963747/3/3_org_zoom.jpg"
        },
      ],
      detail: {
        en: {
          label: "MİMOZA NAPKIN HOLDER FOR RESTAURANT",
          alt: "Mimoza Wooden Desktop Horizontal Napkin Holder African Walnut",
          description:
            "Mimoza Wooden Napkin Holder Desktop napkin dispenser with wooden high quality coil spring system for restaurants, cafes, tea gardens.\nWe will send your Mimoza Napnkin Holder product with invoice of your name",
          size: `Material:Wood MDF\nColor: White\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 7,5 cm.`,
        },
        tr: {
          label: "MİMOZA PEÇETELİK DİSPENSER RESTORAN TİPİ",
          alt: "Mimoza Ahşap Masaüstü Yatay Peçetelik Afrika Ceviz Dispenser",
          description:
            "Mimoza Ahşap Peçetelik - Dispenser Restaurant, kafeterya, çay bahçelerine, ahşap kaliteli helezon yay sistemli masaüstü peçete dispenseri.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
          size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 7,5 cm.`,
        },
        isShowMainPage: true,
        price: 130,
        mainPrice: 170,
        specialPrice: [{offer: 6, price: 540},
        {offer: 12, price: 1060},
        {offer: 24, price: 2030}],
        trendyolUrl:
"https://www.trendyol.com/mimoza/ahsap-masaustu-yatay-pecetelik-afrika-ceviz-p-410431875?merchantId=227159",
         hepsiburadaUrl:"https://www.hepsiburada.com/mimoza-ahsap-masaustu-yatay-pecetelik-dispenser-afrika-ceviz-p-HBCV00003BVNXX?magaza=MimozaStore",
      },
  },
    "wenge": {
      images: [
        {
          imgPath:
"https://cdn.dsmcdn.com/ty777/product/media/images/20230314/8/303165104/885573781/1/1_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty777/product/media/images/20230314/8/303165104/885573781/2/2_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty778/product/media/images/20230314/8/303165104/885573781/3/3_org_zoom.jpg"
        },
      ],
      detail: {
        en: {
          label: "MİMOZA NAPKIN HOLDER FOR RESTAURANT",
          alt: "Mimoza Wooden Desktop Horizontal Napkin Holder African Walnut",
          description:
            "Mimoza Wooden Napkin Holder Desktop napkin dispenser with wooden high quality coil spring system for restaurants, cafes, tea gardens.\nWe will send your Mimoza Napnkin Holder product with invoice of your name",
          size: `Material:Wood MDF\nColor: White\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 7,5 cm.`,
        },
        tr: {
          label: "MİMOZA PEÇETELİK DİSPENSER RESTORAN TİPİ",
          alt: "Mimoza Ahşap Masaüstü Yatay Peçetelik Afrika Ceviz Dispenser",
          description:
            "Mimoza Ahşap Peçetelik - Dispenser Restaurant, kafeterya, çay bahçelerine, ahşap kaliteli helezon yay sistemli masaüstü peçete dispenseri.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
          size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 7,5 cm.`,
        },
        isShowMainPage: true,
        price: 130,
        mainPrice: 170,
        specialPrice: [{offer: 6, price: 540},
        {offer: 12, price: 1060},
        {offer: 24, price: 2030}],
        trendyolUrl:
"https://www.trendyol.com/mimoza/ahsap-masaustu-yatay-pecetelik-dispenser-venge-p-669567263?boutiqueId=61&merchantId=227159",
        hepsiburadaUrl: "https://www.hepsiburada.com/mimoza-ahsap-masaustu-yatay-pecetelik-dispenser-venge-p-HBCV00003YZB52?magaza=MimozaStore",
      },
  },
    "walnut": {
    images: [
      {
        imgPath:
"https://cdn.dsmcdn.com/ty890/product/media/images/20230518/10/351651543/937895264/1/1_org_zoom.jpg"
      },
      {
        imgPath:
"https://cdn.dsmcdn.com/ty888/product/media/images/20230518/10/351651543/937895264/2/2_org_zoom.jpg"
      },
      {
        imgPath:
"https://cdn.dsmcdn.com/ty888/product/media/images/20230518/10/351651543/937895264/3/3_org_zoom.jpg"
      },
    ],
    detail: {
      en: {
        label: "MİMOZA NAPKIN HOLDER FOR RESTAURANT",
        alt: "Mimoza Wooden Desktop Horizontal Napkin Holder Walnut",
        description:
          "Mimoza Wooden Napkin Holder Desktop napkin dispenser with wooden high quality coil spring system for restaurants, cafes, tea gardens.\nWe will send your Mimoza Napnkin Holder product with invoice of your name",
        size: `Material:Wood MDF\nColor: Walnut\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 7,5 cm.`,
      },
      tr: {
        label: "MİMOZA PEÇETELİK DİSPENSER RESTORAN TİPİ",
        alt: "Mimoza Ahşap Masaüstü Yatay Peçetelik Ceviz Dispenser",
        description:
          "Mimoza Ahşap Peçetelik - Dispenser Restaurant, kafeterya, çay bahçelerine, ahşap kaliteli helezon yay sistemli masaüstü peçete dispenseri.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
        size: `Malzeme:Ahşap MDF. Lam \nRenk: Ceviz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 7,5 cm.`,
      },
      isShowMainPage: true,
      price: 130,
      mainPrice: 170,
      specialPrice: [{offer: 6, price: 540},
      {offer: 12, price: 1060},
      {offer: 24, price: 2030}],
      trendyolUrl: "https://www.trendyol.com/mimoza/ahsap-masaustu-yatay-pecetelik-restaurant-dispenser-ceviz-p-710393102?merchantId=227159",
      hepsiburadaUrl: "https://www.hepsiburada.com/mimoza-ahsap-masaustu-yatay-pecetelik-dispenser-p-HBCV00004KLJ2F?magaza=MimozaStore"
    },
},
  },
  "napkin-holder-vertical": {
    "african-walnut": {
      images: [
        {
          imgPath:
"https://cdn.dsmcdn.com/ty957/product/media/images/20230701/22/390299574/973865826/1/1_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty959/product/media/images/20230701/22/390299574/973865826/2/2_org_zoom.jpg"
        },
      ],
      detail: {
        en: {
          label: "MİMOZA VERTICAL NAPKIN HOLDER FOR RESTAURANT",
          alt: "Mimoza Wooden Desktop Vertical Napkin Holder African Walnut",
          description:
            "Mimoza Wooden Napkin Holder Desktop napkin dispenser with wooden high quality coil spring system for restaurants, cafes, tea gardens.\nWe will send your Mimoza Napnkin Holder product with invoice of your name",
          size: `Material:Wood MDF\nColor: African Walnut\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 7,5 cm.`,
        },
        tr: {
          label: "MİMOZA DİKEY PEÇETELİK DİSPENSER RESTORAN TİPİ",
          alt: "Mimoza Ahşap Masaüstü Dikey Peçetelik Afrika Ceviz Dispenser",
          description:
            "Mimoza Ahşap Peçetelik - Dispenser Restaurant, kafeterya, çay bahçelerine, ahşap kaliteli helezon yay sistemli masaüstü peçete dispenseri.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
          size: `Malzeme:Ahşap MDF. Lam \nRenk: Afrika Ceviz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 7,5 cm.`,
        },
        isShowMainPage: true,
        price: 135,
        mainPrice: 175,
        specialPrice: [{offer: 6, price: 600},
        {offer: 12, price: 1150},
        {offer: 24, price: 2235}],
        trendyolUrl:
"https://www.trendyol.com/mimoza/ahsap-masaustu-yatay-pecetelik-afrika-ceviz-p-410431875?merchantId=227159",
         hepsiburadaUrl:"https://www.hepsiburada.com/mimoza-ahsap-masaustu-yatay-pecetelik-dispenser-afrika-ceviz-p-HBCV00003BVNXX?magaza=MimozaStore",
      },
  },
    "wenge": {
      images: [
        {
          imgPath:
"https://cdn.dsmcdn.com/ty889/product/media/images/20230518/15/351837334/941098684/1/1_org_zoom.jpg"
        },
        {
          imgPath:
"https://cdn.dsmcdn.com/ty888/product/media/images/20230518/15/351837334/941098684/2/2_org_zoom.jpg"
        },
      ],
      detail: {
        en: {
          label: "MİMOZA VERTICAL NAPKIN HOLDER FOR RESTAURANT",
          alt: "Mimoza Wooden Desktop Vertical Napkin Holder African Walnut",
          description:
            "Mimoza Wooden Napkin Holder Desktop napkin dispenser with wooden high quality coil spring system for restaurants, cafes, tea gardens.\nWe will send your Mimoza Napnkin Holder product with invoice of your name",
          size: `Material:Wood MDF\nColor: Wenge\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 7,5 cm.`,
        },
        tr: {
          label: "MİMOZA DİKEY PEÇETELİK DİSPENSER RESTORAN TİPİ",
          alt: "Mimoza Ahşap Masaüstü Dikey Peçetelik Afrika Ceviz Dispenser",
          description:
            "Mimoza Ahşap Peçetelik - Dispenser Restaurant, kafeterya, çay bahçelerine, ahşap kaliteli helezon yay sistemli masaüstü peçete dispenseri.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
          size: `Malzeme:Ahşap MDF. Lam \nRenk: Venge\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 7,5 cm.`,
        },
        isShowMainPage: true,
        price: 135,
        mainPrice: 175,
        specialPrice: [{offer: 6, price: 600},
        {offer: 12, price: 1150},
        {offer: 24, price: 2235}],
        trendyolUrl:
        "https://www.trendyol.com/mimoza/ahsap-masaustu-dikey-pecetelik-restaurant-dispenser-venge-p-713052892",
        hepsiburadaUrl: "https://www.hepsiburada.com/mimoza-ahsap-masaustu-yatay-pecetelik-dispenser-venge-p-HBCV00003YZB52?magaza=MimozaStore",
      },
  },
    "walnut": {
    images: [
      {
        imgPath:
"https://cdn.dsmcdn.com/ty890/product/media/images/20230518/10/351651699/937898241/1/1_org_zoom.jpg"
      },
      {
        imgPath:
"https://cdn.dsmcdn.com/ty888/product/media/images/20230518/10/351651699/937898241/2/2_org_zoom.jpg"
      },
    ],
    detail: {
      en: {
        label: "MİMOZA VERTICAL NAPKIN HOLDER FOR RESTAURANT",
        alt: "Mimoza Wooden Desktop Vertical Napkin Holder Walnut",
        description:
          "Mimoza Wooden Napkin Holder Desktop napkin dispenser with wooden high quality coil spring system for restaurants, cafes, tea gardens.\nWe will send your Mimoza Napnkin Holder product with invoice of your name",
        size: `Material:Wood MDF\nColor: Walnut\nWeight: 315 gr.\nWidth: 10,5 cm.\nLength: 16 cm.\nHeight: 7,5 cm.`,
      },
      tr: {
        label: "MİMOZA DİKEY PEÇETELİK DİSPENSER RESTORAN TİPİ",
        alt: "Mimoza Ahşap Masaüstü Dikey Peçetelik Ceviz Dispenser",
        description:
          "Mimoza Ahşap Peçetelik - Dispenser Restaurant, kafeterya, çay bahçelerine, ahşap kaliteli helezon yay sistemli masaüstü peçete dispenseri.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
        size: `Malzeme:Ahşap MDF. Lam \nRenk: Ceviz\nAğırlık: 315 gr.\nEn: 10,5 cm.\nBoy: 16 cm.\nYükseklik: 7,5 cm.`,
      },
      isShowMainPage: true,
      price: 135,
      mainPrice: 175,
      specialPrice: [{offer: 6, price: 600},
      {offer: 12, price: 1150},
      {offer: 24, price: 2235}],
      trendyolUrl: "https://www.trendyol.com/mimoza/ahsap-masaustu-dikey-pecetelik-restaurant-dispenser-ceviz-p-710395685",
      hepsiburadaUrl: "https://www.hepsiburada.com/mimoza-ahsap-masaustu-yatay-pecetelik-dispenser-p-HBCV00004KLJ2F?magaza=MimozaStore"
    },
},
  },
  "napkin-holder-home": {
    white: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty521/product/media/images/20220901/10/167929041/556607494/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty520/product/media/images/20220901/10/167929041/556607494/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty520/product/media/images/20220901/10/167929041/556607494/3/3_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA NAPKIN HOLDER FOR HOME",
            alt:"Mimoza Dignity Woden Countertop Napkin Holder Countertop Napkin Holder Horizontal Home Type Napkin Holder White",
            description:
"Mimoza Dignity Napkin Holder with large sizes, lower lid is fully openable to slides, Our designs are will be taken attention and important parts in your kitchen, our special design products are will add a very different atmosphere. You can easily obtain napkins with Maylo Puff Handkerchief type products available in almost every market, you can remove the package and place it from the sliding lower lid and use it immediately.\nWe will send your Mimoza Dignity Bread Box product with invoice of your name",
            size: `Material:Wood MDF\nColor: White\nWeight:\nWidth: 12 cm.\nLength: 20.5 cm.\nHeight: 10 cm.`,
          },
          tr: {
            label: "MİMOZA PEÇETELİK EV TİPİ",
            alt:"Mimoza Dignity Ahşap Tezgah Üstü Peçetelik Masaüstü Peçetelik Yatay Ev Tipi Peçetelik Beyaz",
            description:
              "Mimoza Ahşap Ev Tipi Peçetelik geniş boyutları, alttan tamamen açılabilir sürgülü kapağı, modern beyaz tasarımıyla mutfağınızın en dikkat çeken ve önemli üyelerinden biri olacak. Dignity Ekmek Kutusu ile birlikte kullanılarak mutfağınıza çok ayrı bir hava katacak. Maylo Puf Mendil tipi ürünler ile rahatlıkla peçeteleri temin edebilir, paketini sökerek sürgülü bölümden yerleştirip hemen kullanabilirsiniz.\nMimoza Ahşap Peçetelik, Dispenser adınıza faturalı ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam \nRenk: Mat Beyaz\nAğırlık:\nEn: 12 cm.\nBoy: 20.5 cm.\nYükseklik: 10 cm.`,
          },
          isShowMainPage: true,
          price: 0,
          mainPrice: 0,
          trendyolUrl:
"https://www.trendyol.com/mimoza/dignity-ahsap-tezgah-ustu-pecetelik-masaustu-pecetelik-yatay-ev-tipi-pecetelik-beyaz-p-347604157?boutiqueId=61&merchantId=227159",
          // hepsiburadaUrl:
          //   "https://www.hepsiburada.com/mimoza-ahsap-posta-kutusu-beyaz-p-HBCV00000FLDB8",
        },
    },

  },
  "napkin-holder-with-handle": {
    white: {
      black: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty578/product/media/images/20221024/11/201272598/605744017/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty576/product/media/images/20221024/11/201272598/605744017/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty578/product/media/images/20221024/11/201272598/605744017/3/3_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA NAPKIN HOLDER WITH HANDLE",
            alt: "Mimoza White Wooden Napkin Holder Black Handle",
            description:
              "Mimoza Napkin Holder With Handle, Our handmade products produced with wooden MDF are excellent products in terms of texture and color tone.\nOur product will be sent a piece.\nWe will send your Mimoza Napkin Holder With Handle product with invoice of your name",
            size: `Material:Wood MDF\n`,
          },
          tr: {
            label: "MİMOZA KULPLU PEÇETELİK",
            alt: "Mimoza Beyaz Ahşap Peçetelik Siyah Kulplu",
            description:
              "Mimoza Kulplu Peçetelik, Ahşap MDFlam ile üretilen el yapımı ürünlerimiz, doku ve renk tonu bakımından mükemmel üründür.\nÜrünümüz tek olarak gönderilecektir.\nMimoza Kulplu Peçetelik adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam`,
          },
          isShowMainPage: true,
          price: 115,
          mainPrice: 170,
          trendyolUrl:
"https://www.trendyol.com/pd/mimoza/beyaz-ahsap-pecetelik-siyah-p-374769253?boutiqueId=61&merchantId=227159&filterOverPriceListings=false",
//           hepsiburadaUrl:
// "https://www.hepsiburada.com/mimoza-dignity-ahsap-ekmek-kutusu-beyaz-siyah-kulplu-p-HBCV00000QUZFR",
        },
      },
      chromium: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty578/product/media/images/20221024/11/201275325/605748782/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty578/product/media/images/20221024/11/201275325/605748782/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty576/product/media/images/20221024/11/201275325/605748782/3/3_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA NAPKIN HOLDER WITH HANDLE",
            alt: "Mimoza White Wooden Napkin Holder Chromium Handle",
            description:
              "Mimoza Napkin Holder With Handle, Our handmade products produced with wooden MDF are excellent products in terms of texture and color tone.\nOur product will be sent a piece.\nWe will send your Mimoza Napkin Holder With Handle product with invoice of your name",
            size: `Material:Wood MDF\n`,
          },
          tr: {
            label: "MİMOZA KULPLU PEÇETELİK",
            alt: "Mimoza Beyaz Ahşap Peçetelik Krom Kulplu",
            description:
              "Mimoza Kulplu Peçetelik, Ahşap MDFlam ile üretilen el yapımı ürünlerimiz, doku ve renk tonu bakımından mükemmel üründür.\nÜrünümüz tek olarak gönderilecektir.\nMimoza Kulplu Peçetelik adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam`,
          },
          isShowMainPage: true,
          price: 135,
          mainPrice: 190,
          trendyolUrl:
"https://www.trendyol.com/pd/mimoza/beyaz-ahsap-pecetelik-krom-p-374771532?boutiqueId=61&merchantId=227159&filterOverPriceListings=false",
          //           hepsiburadaUrl:
// "https://www.hepsiburada.com/mimoza-dignity-ahsap-ekmek-kutusu-beyaz-siyah-kulplu-p-HBCV00000QUZFR",
        },
      },
      gold: {
        images: [
          {
            imgPath:
"https://cdn.dsmcdn.com/ty577/product/media/images/20221024/10/201194349/605631574/1/1_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty578/product/media/images/20221024/10/201194349/605631574/2/2_org_zoom.jpg"
          },
          {
            imgPath:
"https://cdn.dsmcdn.com/ty578/product/media/images/20221024/10/201194349/605631574/3/3_org_zoom.jpg"
          },
        ],
        detail: {
          en: {
            label: "MİMOZA NAPKIN HOLDER WITH HANDLE",
            alt: "Mimoza White Wooden Napkin Holder Gold Handle",
            description:
              "Mimoza Napkin Holder With Handle, Our handmade products produced with wooden MDF are excellent products in terms of texture and color tone.\nOur product will be sent a piece.\nWe will send your Mimoza Napkin Holder With Handle product with invoice of your name",
            size: `Material:Wood MDF\n`,
          },
          tr: {
            label: "MİMOZA KULPLU PEÇETELİK",
            alt: "Mimoza Beyaz Ahşap Peçetelik Altın Kulplu",
            description:
              "Mimoza Kulplu Peçetelik, Ahşap MDFlam ile üretilen el yapımı ürünlerimiz, doku ve renk tonu bakımından mükemmel üründür.\nÜrünümüz tek olarak gönderilecektir.\nMimoza Kulplu Peçetelik adınıza faturalı orijinal ürün etiketli ve özenle paketlenmiş olarak size kargolanacaktır.",
            size: `Malzeme:Ahşap MDF. Lam`,
          },
          isShowMainPage: true,
          price: 135,
          mainPrice: 190,
          trendyolUrl:
"https://www.trendyol.com/pd/mimoza/beyaz-ahsap-pecetelik-altin-p-374695620?boutiqueId=61&merchantId=227159&filterOverPriceListings=false",
          //           hepsiburadaUrl:
// "https://www.hepsiburada.com/mimoza-dignity-ahsap-ekmek-kutusu-beyaz-siyah-kulplu-p-HBCV00000QUZFR",
        },
      },
    },
  },
};
export const productColorList = {
  "bread-box": {
    white: {
      black: { link: "/product-details/bread-box/white/black" },
      chromium: { link: "/product-details/bread-box/white/chromium" },
    },
  },
  "bread-box-without-legs": {
    white: {
      black: { link: "/product-details/bread-box-without-legs/white/black" },
      chromium: { link: "/product-details/bread-box-without-legs/white/chromium" },
    },
  },
  "mail-box": {
    white: {link: "/product-details/mail-box/white" },
    walnut: {link: "/product-details/mail-box/walnut" },
  },
  "napkin-holder": {
    white: {link: "/product-details/napkin-holder/white" },
    "african-walnut": {link: "/product-details/napkin-holder/african-walnut" },
    wenge: {link: "/product-details/napkin-holder/wenge" },
    "walnut": {link: "/product-details/napkin-holder/walnut"}
  },
  "napkin-holder-vertical": {
    "african-walnut": {link: "/product-details/napkin-holder-vertical/african-walnut" },
    wenge: {link: "/product-details/napkin-holder-vertical/wenge" },
    "walnut": {link: "/product-details/napkin-holder-vertical/walnut"}
  },
  "napkin-holder-home": {
    white: {link: "/product-details/napkin-holder-home/white" },
    //walnut: {link: "/product-details/mail-box/walnut" },
  },
  "napkin-holder-with-handle": {
    white: {
      black: { link: "/product-details/napkin-holder-with-handle/white/black" },
      chromium: { link: "/product-details/napkin-holder-with-handle/white/chromium" },
      gold: { link: "/product-details/napkin-holder-with-handle/white/gold" },

    },
  },
};
export const mainPageList = {
  "bread-box-white-black": {
    product: productList['bread-box'].white.black,
    link: productColorList['bread-box'].white.black.link,
    width: "40%"
  },
  "mail-box-white": {
    product: productList['mail-box'].white,
    link: productColorList['mail-box'].white.link,
    width: "20%"
  },
  "napkin-holder-african-walnut": {
    product: productList['napkin-holder']["african-walnut"],
    link: productColorList['napkin-holder']["african-walnut"].link,
    width: "40%"
  },
  "napkin-holder-walnut": {
    product: productList['napkin-holder']["walnut"],
    link: productColorList['napkin-holder']["walnut"].link,
    width: "30%"
  },
  "bread-box-without-legs-white-chromium": {
    product: productList['bread-box-without-legs'].white.chromium,
    link: productColorList['bread-box-without-legs'].white.chromium.link,
    width: "40%"
  },
  "napkin-holder-vertical-walnut": {
    product: productList['napkin-holder-vertical']["walnut"],
    link: productColorList['napkin-holder-vertical']["walnut"].link,
    width: "30%"
  },
};

export const allProductPageList = {
  "bread-box-white-black": {
    product: productList['bread-box'].white.black,
    link: productColorList['bread-box'].white.black.link,
    width: "20%"
  },
  "bread-box-white-chromium": {
    product: productList['bread-box'].white.chromium,
    link: productColorList['bread-box'].white.chromium.link,
    width: "20%"
  },
  "bread-box-without-legs-white-black": {
    product: productList['bread-box-without-legs'].white.black,
    link: productColorList['bread-box-without-legs'].white.black.link,
    width: "20%"
  },
  "bread-box-without-legs-white-chromium": {
    product: productList['bread-box-without-legs'].white.chromium,
    link: productColorList['bread-box-without-legs'].white.chromium.link,
    width: "20%"
  },
  "mail-box-white": {
    product: productList['mail-box'].white,
    link: productColorList['mail-box'].white.link,
    width: "20%"
  },
  "mail-box-walnut": {
    product: productList['mail-box'].walnut,
    link: productColorList['mail-box'].walnut.link,
    width: "20%"
  },
  "napkin-holder-african-walnut": {
    product: productList['napkin-holder']["african-walnut"],
    link: productColorList['napkin-holder']["african-walnut"].link,
    width: "20%"
  },
    "napkin-holder-wenge": {
    product: productList['napkin-holder']["wenge"],
    link: productColorList['napkin-holder']["wenge"].link,
    width: "20%"
  },
  "napkin-holder-walnut": {
    product: productList['napkin-holder']["walnut"],
    link: productColorList['napkin-holder']["walnut"].link,
    width: "20%"
  },
  "napkin-holder-white": {
    product: productList['napkin-holder'].white,
    link: productColorList['napkin-holder'].white.link,
    width: "20%"
  },
  "napkin-holder-vertical-african-walnut": {
    product: productList['napkin-holder-vertical']["african-walnut"],
    link: productColorList['napkin-holder-vertical']["african-walnut"].link,
    width: "20%"
  },
    "napkin-holder-vertical-wenge": {
    product: productList['napkin-holder-vertical']["wenge"],
    link: productColorList['napkin-holder-vertical']["wenge"].link,
    width: "20%"
  },
  "napkin-holder-vertical-walnut": {
    product: productList['napkin-holder-vertical']["walnut"],
    link: productColorList['napkin-holder-vertical']["walnut"].link,
    width: "20%"
  },
  "napkin-holder-home-white": {
    product: productList['napkin-holder-home'].white,
    link: productColorList['napkin-holder-home'].white.link,
    width: "20%"
  },
  "napkin-holder-with-handle-white-black": {
    product: productList['napkin-holder-with-handle'].white.black,
    link: productColorList['napkin-holder-with-handle'].white.black.link,
    width: "20%"
  },
  "napkin-holder-with-handle-white-chromium": {
    product: productList['napkin-holder-with-handle'].white.chromium,
    link: productColorList['napkin-holder-with-handle'].white.chromium.link,
    width: "20%"
  },
  "napkin-holder-with-handle-white-gold": {
    product: productList['napkin-holder-with-handle'].white.gold,
    link: productColorList['napkin-holder-with-handle'].white.gold.link,
    width: "20%"
  },
};
