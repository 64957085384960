import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '70%',
  //maxHeight: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};

export default function ImageModal({isOpen, imageUrl}) {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  useEffect( () => {
    setOpen(!open)
 
   },[isOpen]);
   
  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
              component="img"
              src={imageUrl}
              />

      </Modal>
  );
}
