import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../components/Typography";
import { localizeText } from "../../utils/locale";
import help from "../../images/icons/help.png";
import Link from "@material-ui/core/Link";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  help: {
    width: 60,
  },
});

function ProductSmokingHero(props) {
  const { classes, selectedLanguage } = props;

  return (
    <Container className={classes.root} component="section">
      <Button className={classes.button}>
        <Link variant="h6" underline="none" href="/contact">
          <Typography variant="h4" component="span">
            {localizeText("askQuestionHeader", selectedLanguage)}
          </Typography>
        </Link>
      </Button>
      <Typography variant="subtitle1" className={classes.link}>
        {localizeText("askQuestionHeader2", selectedLanguage)}
      </Typography>
      <img src={help} className={classes.help} alt="help" />
    </Container>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
