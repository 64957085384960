import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import Cookies from "js-cookie";
import Product from "./Product";
import ProductList from "./ProductList";

const App = () => {
  const [selectedLanguage] = useState(
    (
      Cookies.get("language") ||
      navigator.language ||
      navigator.userLanguage
    ).substring(0, 2) || "tr"
  );

  useEffect(() => {
    Cookies.set("language", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Home selectedLanguage={selectedLanguage} />}
          >
            {/* <Route path="/contact" element={<Contact />} /> */}
          </Route>
          <Route
            path="/contact"
            element={<Contact selectedLanguage={selectedLanguage} />}
          />
          <Route
            path="/product-details/:id/:productColor/:handleColor"
            element={<Product selectedLanguage={selectedLanguage} />}
          />
          <Route
            path="/product-details/:id/:productColor"
            element={<Product selectedLanguage={selectedLanguage} />}
          />
          <Route
            path="/all-products"
            element={<ProductList selectedLanguage={selectedLanguage} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
