import React, { useState } from "react";
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";
import { MenuItem } from "@material-ui/core";
import hepsiburada from "../../images/logo/hepsiburada-logo.png";
import trendyol from "../../images/logo/trendyol-logo.png";
import { localizeText } from "../../utils/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#f1f1f1",
    //backgroundColor: theme.palette.secondary.light,
    bottom: 0,
    right: 0,
    left: 0,
  },
  rootForWithoutMainPage: {
    display: "flex",
    backgroundColor: "#f1f1f1",
    //backgroundColor: theme.palette.secondary.light,
    bottom: 0,
    width: "100%",
    position: "fixed",

  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  marketPlaceText: {
    fontSize: 16,
  },
  iconsWrapper: {
    height: 64,
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(4),
    "&:hover": {
      // backgroundColor: theme.palette.warning.dark
    },
  },
  list: {
    margin: 0,
    listStyle: "none",
    paddingLeft: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  languageWrapper: {
    marginLeft: theme.spacing(8),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

const LANGUAGES = [
  {
    code: "tr",
    name: "Türkçe",
  },
  {
    code: "en",
    name: "English",
  },
];

export default function AppFooter(props) {
  const classes = useStyles();
  const { selectedLanguage, withoutMainPage } = props;
  const [selectedLanguageChild] =
    useState(selectedLanguage);

  const changeLanguage = async (lang) => {
    if (selectedLanguageChild === lang) {
      return;
    }

    await Cookies.set("language", lang);
    window.location.reload();
  };

  return (
    <Typography
      component="footer"
      className={
        !withoutMainPage ? classes.root : classes.rootForWithoutMainPage
      }
    >
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={3} sm={2} md={1} lg={3}>
            <Typography
              className={classes.marketPlaceText}
              variant="h6"
              marked="left"
              gutterBottom
            >
              {localizeText("forStorePageHeader", selectedLanguageChild)}
            </Typography>
            <Grid
              container
              direction="column"
              justify="flex-end"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons}>
                <a
                  href="https://www.trendyol.com/magaza/mimoza-m-227159?sst=0"
                  className={classes.icon}
                  target="_blank"
                >
                  <img src={trendyol} alt="trendyol" />
                </a>

                <a
                  href="https://www.hepsiburada.com/magaza/mimozastore"
                  className={classes.icon}
                  target="_blank"
                >
                  <img src={hepsiburada} alt="hepsiburada" />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} md={4} lg={3} className={classes.languageWrapper}>
            <Typography variant="h6" marked="left" gutterBottom>
              {localizeText("language", selectedLanguageChild)}
            </Typography>
            <TextField
              id="outlined-select-currency"
              select
              className={classes.language}
              value={selectedLanguageChild}
              onChange={(val) => changeLanguage(val.target.value)}
            >
              {LANGUAGES.map((language) => (
                <MenuItem value={language.code} key={language.code}>
                  {language.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8} sm={4} md={2} lg={3} className={classes.languageWrapper}>
          <Typography
            className={classes.marketPlaceText}
            variant="h5"
            marked="center"
            alt="Mimoza Store"
          >
            © 2022 Mimoza Store
          </Typography>
          </Grid>
   
          {/* <Grid item>
            <Typography variant="caption">
              {"Icons made by "}
              <Link
                href="https://www.freepik.com"
                rel="nofollow"
                title="Freepik"
              >
                Freepik
              </Link>
              {" from "}
              <Link
                href="https://www.flaticon.com"
                rel="nofollow"
                title="Flaticon"
              >
                www.flaticon.com
              </Link>
              {" is licensed by "}
              <Link
                href="https://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </Link>
            </Typography>
          </Grid> */}
        </Grid>
      </Container>
    </Typography>
  );
}
