import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { localizeText } from "../../utils/locale";
import kitchenBackground from '../../images/mimoza-store-luxury-kitchen-furniture.webp'

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${kitchenBackground})`,
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center",
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero(props) {
  const { classes, selectedLanguage } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background} alt="" >
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: "none" }} />
      <br />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        <br />
        {localizeText("header", selectedLanguage)}
      </Typography>
      <br />
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        {localizeText("paragraph", selectedLanguage)}
      </Typography>
      <Typography color="inherit" align="center" variant="h2" marked="center" />
      <Typography variant="body2" color="inherit" className={classes.more}>
        {localizeText("more", selectedLanguage)}
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
