import React, { Fragment } from "react";
import withRoot from "./modules/withRoot";

// components
import NavbarView from "./modules/views/NavbarView";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductCategories from "./modules/views/ProductCategories";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";

const Home = (props) => {
  const { selectedLanguage } = props;
  return (
    <Fragment>
      <NavbarView selectedLanguage={selectedLanguage} />
      <ProductHero selectedLanguage={selectedLanguage} />
      <ProductValues selectedLanguage={selectedLanguage} />
      <ProductCategories
        selectedLanguage={selectedLanguage}
        isHomePage={true}
      />
      <ProductHowItWorks selectedLanguage={selectedLanguage} />
      <ProductSmokingHero selectedLanguage={selectedLanguage} />
      <AppFooter selectedLanguage={selectedLanguage} withoutMainPage={false}/>
    </Fragment>
  );
};

export default withRoot(Home);
