import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import { localizeText } from "../../utils/locale";
import { allProductPageList, mainPageList } from "../../utils/productList";
import Link from "@material-ui/core/Link";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
      borderRadius: 12,
    },
    "&:hover $productListImageBackdrop": {
      opacity: 0.15,
      borderRadius: 12,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "solid",
    borderWidth: "2px",
    color: theme.palette.common.white,
  },
  productListImageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    // top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // borderStyle: 'solid',
    borderWidth: "1px",
    color: theme.palette.common.black,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 50%",
    border: "1px solid rgb(229, 229, 229)",
    borderRadius: 12,
  },
  productListImageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 50%",
    border: "1px solid rgb(229, 229, 229)",
    borderRadius: 12,
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
    border: "1px solid rgb(229, 229, 229)",
    borderRadius: 12,
  },
  productListImageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  productListImageTitle: {
    position: "relative",
    color: "rgb(72, 72, 72)",
    // fontSize: 12,
    fontWeight: 600,
    lineHeight: "16px",
    padding: 4,
    // padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  forAllProduct: {
    marginTop: 16,
    textAlign: "center",
    width: "100%",
    border: "solid 2px",
    borderColor: theme.palette.secondary.main,
  },
});

function ProductCategories(props) {
  const { classes, selectedLanguage, isHomePage } = props;
  const productList = isHomePage ? mainPageList : allProductPageList;

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        {localizeText("productHeader", selectedLanguage)}
      </Typography>
      <div className={classes.images}>
        {Object.keys(productList).map((item) => {
          const storeProduct = productList[item];
          return (
            <ButtonBase
              key={item}
              alt={storeProduct.product.detail[selectedLanguage].alt}
              className={classes.imageWrapper}
              style={{
                width: storeProduct.width,
                marginRight: isHomePage ? 0 : 16,
                marginTop: isHomePage ? 0 : 16,
                padding: isHomePage ? "" : 155,
              }}
            >
              <Link href={storeProduct.link}>
                <div
                  className={
                    isHomePage ? classes.imageSrc : classes.productListImageSrc
                  }
                  style={{
                    backgroundImage: `url(${storeProduct.product.images[0].imgPath})`,
                  }}
                />
                <div
                  className={
                    isHomePage
                      ? classes.imageBackdrop
                      : classes.productListImageBackdrop
                  }
                />
                <div
                  className={
                    isHomePage
                      ? classes.imageButton
                      : classes.productListImageButton
                  }
                >
                  <Typography
                    component="h3"
                    variant={isHomePage ? "h6" : "subtitle2"}
                    alt={storeProduct.product.detail[selectedLanguage].alt}
                    color="inherit"
                    className={
                      isHomePage
                        ? classes.imageTitle
                        : classes.productListImageTitle
                    }
                  >
                    {storeProduct.product.detail[selectedLanguage].label}
                    <div className={classes.imageMarked} />
                  </Typography>
                </div>
              </Link>
            </ButtonBase>
          );
        })}
        {isHomePage && (
          <div className={classes.forAllProduct} style={{}}>
            <Link
              variant="h6"
              underline="none"
              href="/all-products"
              className={classes.linkSecondary}
            >
              {localizeText("forAllProducts", selectedLanguage)}
            </Link>
          </div>
        )}
      </div>
    </Container>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
