import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import productCTAImageDots from "../../images/productCTAImageDots.png";
import whatsappLogo from "../../images/icons/whatsapp-logo.ico";
import { localizeText } from "../../utils/locale";
import SwipeableTextMobileStepper from "../components/SwipeableTextMobileStepper";
import { productColorList } from "../../utils/productList";
import hepsiburada from "../../images/logo/hepsiburada-logo.png";
import trendyol from "../../images/logo/trendyol-logo.png";
import { calculatePercentageWithTwoNumber } from "../../utils/functions";
import Link from "@material-ui/core/Link";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: 0,
    display: "flex",
    flexDirection: "column",
    top: 0,
    bottom: 0,
  },
  body: {
    flex: 4,
  },
  cardWrapper: {
    zIndex: 1,
    //background:'red'
  },
  card: {
    display: "flex",
    justifyContent: "center",
    //padding: theme.spacing(8, 3)
  },
  cardContent: {
    width: "100%",
    paddingLeft: 32,
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
  },
  imagesWrapper: {
    position: "relative",
    zIndex: 1,
    maxHeight: 500,
  },
  imageDots: {
    position: "absolute",
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: "100%",
    background: `url(${productCTAImageDots})`,
    maxHeight: 400,
  },
  image: {
    position: "absolute",
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: "80%",
    //maxWidth: 500
  },
  iconsWrapper: {
    height: 64,
  },
  icons: {
    paddingLeft: 32,
    paddingBottom: 32,
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(4),
    "&:hover": {
      // backgroundColor: theme.palette.warning.dark
    },
  },
  price: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: 32,
  },
  transfer: {
    color: "green",
    fontSize: 25,
    fontWeight: 700,
  },
  mainPrice: {
    color: "#1d2025",
    fontSize: 25,
    fontWeight: 700,
  },
  specialOffer: {
    color: "#A52A2A",
    fontSize: 16,
    fontWeight: 700,
  },
  oldPrice: {
    color: "#55565D",
    fontSize: 25,
    fontWeight: 700,
  },
  pricePercentage: {
    backgroundColor: "#ffede0",
    color: "#8F4100",
    //fontSize: 25,
    fontWeight: 700,
    padding: "3px 10px",
    borderRadius: 5,
  },

  mainTag: {
    alignItems: "center",
    display: "flex",
    gap: "15px",
    maxHeight: 32,
  },
  productColorsContainer: {
    alignItems: "flex-start",
    display: "flex",
    gap: "10px",
    padding: 32,
  },
  productColors: {
    backgroundColor: "#ffede0",
    color: "#ff7d19",
    //fontSize: 25,
    fontWeight: 700,
    padding: "3px 10px",
    borderRadius: 15,
  },
});

const ProductDetail = ({
  classes,
  selectedLanguage,
  product,
  productId,
  productColor,
  handleColor,
}) => {
  return (
    product && (
      <Container className={classes.root} component="section">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={4}
            className={classes.cardWrapper}
          >
            <SwipeableTextMobileStepper
              selectedLanguage={selectedLanguage}
              product={product}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            lg={8}
            className={classes.cardWrapper}
          >
            <div className={classes.card}>
              <form className={classes.cardContent}>
                <Typography variant="subtitle1">
                  {/* {localizeText(productList[productId], selectedLanguage)} */}
                </Typography>
                <Typography variant="subtitle1">
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {product.detail[selectedLanguage].description}
                  </div>
                  <br />
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {product.detail[selectedLanguage].size}
                  </div>
                </Typography>
              </form>
            </div>
            <div className={classes.productColorsContainer}>
              {localizeText("colors", selectedLanguage)}
              <br />
              {Object.keys(productColorList[productId]).map(
                (itemColor, index) => {
                  const productColors = productColorList[productId][itemColor];

                  const colorList =
                    productColor && handleColor ? (
                      Object.keys(productColors).map((holderColor, index) => {
                        return (
                          <div
                            className={classes.productColors}
                            alt={product.detail[selectedLanguage].alt}
                            key={index}
                            style={{
                              backgroundColor:
                                productColor === itemColor &&
                                handleColor === holderColor
                                  ? "#FD841F"
                                  : "",
                            }}
                          >
                            <Link
                              href={productColors[holderColor].link}
                              rel="nofollow"
                              title="Flaticon"
                            >
                              {localizeText(itemColor, selectedLanguage) +
                                " " +
                                localizeText("color", selectedLanguage) +
                                " " +
                                localizeText("and", selectedLanguage) +
                                " " +
                                localizeText(holderColor, selectedLanguage) +
                                " " +
                                localizeText("holder", selectedLanguage) +
                                " "}
                            </Link>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className={classes.productColors}
                        style={{
                          backgroundColor:
                            productColor === itemColor ? "#FD841F" : "",
                        }}
                        key={index}
                      >
                        <Link
                          href={productColors.link}
                          rel="nofollow"
                          title="Flaticon"
                        >
                          {localizeText(itemColor, selectedLanguage) +
                            " " +
                            localizeText("color", selectedLanguage)}
                        </Link>
                      </div>
                    );

                  return colorList;
                }
              )}
            </div>
            <div className={classes.price}>
              {productId === "napkin-holder" && (
                <p className={classes.specialOffer}>
                  ** {localizeText("specialOfferText", selectedLanguage)}
                </p>
              )}
              {product.detail.specialPrice?.map((item, index) => (
                <p className={classes.specialOffer} key={index}>
                  {item.offer} {localizeText("pieces", selectedLanguage)} :
                  {/* {item.price}₺ */}
                </p>
              ))}
              <div className={classes.mainTag}>
                <p className={classes.transfer}>
                  {localizeText("remit", selectedLanguage)}
                </p>{" "}
                <p className={classes.mainPrice}>
                  {/* {product.detail.price}₺ */}
                </p>
                <p className={classes.pricePercentage}>
                  {/* {calculatePercentageWithTwoNumber(
                    product.detail.mainPrice,
                    product.detail.price
                  )} */}
                </p>
              </div>
              <s className={classes.oldPrice}>
                {/* {product.detail.mainPrice}₺ */}
              </s>
              {localizeText("remitDescription", selectedLanguage)}
              <a
                href="https://api.whatsapp.com/send?phone=905319670789"
                target={"_blank"}
              >
                <img src={whatsappLogo} alt="whatsapp" />
              </a>
              {localizeText("toMarketPlaces", selectedLanguage)}
            </div>
            <div className={classes.icons}>
              <a
                href={product.detail.trendyolUrl}
                className={classes.icon}
                target="_blank"
              >
                <img src={trendyol} alt="trendyol" />
              </a>

              <a
                href={product.detail.hepsiburadaUrl}
                className={classes.icon}
                target="_blank"
              >
                <img src={hepsiburada} alt="hepsiburada" />
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    )
  );
};

ProductDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductDetail);
