import React, { Fragment } from "react";
import withRoot from "./modules/withRoot";

// components
import NavbarView from "./modules/views/NavbarView";
import AppFooter from "./modules/views/AppFooter";
import ProductCategories from "./modules/views/ProductCategories";

const ProductList = (props) => {
  const { selectedLanguage } = props;

  return (
    <Fragment>
      <NavbarView selectedLanguage={selectedLanguage} />
      <ProductCategories
        selectedLanguage={selectedLanguage}
        isHomePage={false}
      />

      <AppFooter selectedLanguage={selectedLanguage} withoutMainPage={false} />
    </Fragment>
  );
};

export default withRoot(ProductList);
